import pathServices from "../../services/path.services"
import router from "../../router"

export default {
    namespaced: true,
    state: {
        alert: {
            show: false,
            type: "info",
            message: "",
            timeout: 2500,
        },
        loader: false,
        sideBar: true,
        menu: [],
        roles: [],
        rutas: [],
    },
    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },
    mutations: {
        setAlert: (state, payload) => {
            state.alert = payload;
        },
        setLoader: (state, payload) => {
            state.loader = payload;
        },
        setSideBar: (state, payload) => {
            state.sideBar = payload;
        },
        setMenu: (state, menu) => {
            state.menu = menu;
        },
        setRutas: (state, rutas) => {
            state.rutas = rutas;
        },
        setRoles: (state, roles) => {
            state.roles = roles;
        },
    },
    actions: {
        getMenu: async ({commit}) => {
            try {
                const menuJSON = localStorage.getItem('menu')
                const rolesJSON = localStorage.getItem("roles")

                const menuPrincipalConsulta = await pathServices.getMenuPrincipal();                

                const menuPrincipal = {
                    id: 1,
                    titulo: "MENÚ PRINCIPAL",
                    ruta: '/',
                    orden: 0,
                    icono: "mdi-home",
                    visible: true,
                    es_clasificador: false,
                    requiere_autenticacion: true,
                    dependecia: null,
                    roles: {},
                    modulo: {
                        id: menuPrincipalConsulta.data.id,
                        nombre : menuPrincipalConsulta.data.nombre,
                        base_url : menuPrincipalConsulta.data.base_url,
                        icono : menuPrincipalConsulta.data.icono,
                        inactivo : menuPrincipalConsulta.data.inactivo,
                        created_at : menuPrincipalConsulta.data.created_at
                    },
                    dependecias: [],
                }
                
               if (!menuJSON || !rolesJSON) {
                   const { data: { menu, roles }, status } = await pathServices.getAuthorizedPaths();
                   
                   if(status !== 200) {
                        throw new Error("No se pudo obtener el menú")
                   }

                   if(!menu.some(item => item.titulo === menuPrincipal.titulo)){
                    menu.unshift(menuPrincipal)
                   }
                   
                   localStorage.setItem("roles",JSON.stringify(roles))
                   localStorage.setItem("menu",JSON.stringify(menu))
                   commit("setMenu", menu);
                   commit("setRutas", menu);
                   commit("setRoles", roles);
               } else{
                   
                    const menuArray = JSON.parse(menuJSON)
                    const rolesArray = JSON.parse(rolesJSON)

                    if(!menuArray.some(item => item.titulo === menuPrincipal.titulo)){
                        menuArray.unshift(menuPrincipal)
                    }
                   
                   if(!Array.isArray(menuArray) || !Array.isArray(rolesArray) || menuArray.length === 0 || rolesArray.length === 0) {
                       throw new Error("No se pudo obtener el menú")
                   }
                   
                    commit("setMenu", menuArray);
                    commit("setRutas", menuArray);
                    commit("setRoles", rolesArray);
                }
            } catch (error) {
                commit("setAlert", {
                    show: true,
                    type: "error",
                    message: error.message || "No se pudo obtener el menu",
                    timeout: 2500
                })
                localStorage.removeItem("menu")
                localStorage.removeItem("roles")
                localStorage.removeItem("user")
                localStorage.removeItem("token")
                localStorage.removeItem("refresh_token")
                router.push({ name: "login" })
            }

        },
        clearMenu: ({commit}) => {
            commit("setMenu", []);
            commit("setRutas", []);
        }
    },
};

const getNavItem = (items) => {
    return items.filter((item) => {
        if (!item.mostrar) return false
        if (item.childrens?.length > 0) item.childrens = getNavItem(item.childrens)
        return true
    })
}